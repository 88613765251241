import { Resource } from 'react-admin';

import PagesIcon from '@material-ui/icons/ImportContacts';
import NotesIcon from '@material-ui/icons/Note';
import UserIcon from '@material-ui/icons/Group';
import AuthorIcon from '@material-ui/icons/FormatQuote';
import CategoriesIcon from '@material-ui/icons/Widgets';
import TagsIcon from '@material-ui/icons/LocalOffer';
import RoleIcon from '@material-ui/icons/HowToReg';
import SettingsIcon from '@material-ui/icons/Settings';
import MediaIcon from '@material-ui/icons/PhotoLibrary';
import PhotographersIcon from '@material-ui/icons/CameraEnhance';
import CommentIcon from '@material-ui/icons/Comment';
/* 
import PollIcon from '@material-ui/icons/Poll';
import { PollsCreate, PollsEdit, PollsList } from '../../views/polls';
import { NewspapersIcon } from "../../components/Icons/NewspapersIcon/NewspapersIcon"; 
import { NewspapersCreate, NewspapersEdit, NewspapersList, NewspapersShow } from '../../views/newspapers';
*/
import { PagesEdit, PagesList } from '../../views/pages';
import { NotesCreate, NotesEdit, NotesList, NotesShow } from '../../views/notes';
import { JournalistCreate, JournalistEdit, JournalistList } from '../../views/journalists';
import { SubscriberCreate, SubscriberEdit, SubscriberList } from '../../views/subscribers';
import { AuthorCreate, AuthorEdit, AuthorList } from '../../views/authors';
import { CategoriesCreate, CategoriesEdit, CategoriesList, CategoriesShow } from '../../views/categories';
import { TagsCreate, TagsEdit, TagsList, TagsShow } from '../../views/tags';
import { RoleList, RoleEdit, RoleCreate } from '../../views/roles';
import { PhotographersCreate, PhotographersEdit, PhotographersList } from '../../views/photographers';
import { ClubCardList, ClubCardCreate, ClubCardEdit, ClubCardShow } from '../../views/club_cards';
import { BenefitsList } from '../../views/benefits';
import { SettingsList } from '../../views/settings';
import { MediaCreate, MediaEdit, MediaList, MediaShow } from '../../views/media';
import { ContactFormCreate } from "../../views/contact_form_create";
import { CommentsList } from '../../views/comments';
import { NonPermission } from '../../NonPermission';
import ViewUsers from "../../views/metrics/view-users";
import ViewVisitedUrl from "../../views/visited_url/view-visitedurl";
import { messages } from '../../language/language-es';
import { checkPermissions } from '../../utils/validations';

const Resources = (permissions) => [
    <Resource name="notes"
        list={checkPermissions(permissions, 'list-notes') ? NotesList : null}
        edit={checkPermissions(permissions, 'edit-notes') ? NotesEdit : NonPermission}
        create={checkPermissions(permissions, 'create-notes') ? NotesCreate : NonPermission}
        show={NotesShow}
        icon={NotesIcon}
        options={{ label: messages.resources.notes.name }}
    />,
    <Resource name="pages"
        list={checkPermissions(permissions, 'list-pages') ? PagesList : null}
        edit={checkPermissions(permissions, 'edit-pages') ? PagesEdit : NonPermission}
        icon={PagesIcon}
        options={{ label: messages.resources.pages.name }}
    />,
    <Resource name="media"
        list={checkPermissions(permissions, 'list-media') ? MediaList : null}
        edit={checkPermissions(permissions, 'edit-media') ? MediaEdit : NonPermission}
        create={checkPermissions(permissions, 'create-media') ? MediaCreate : NonPermission}
        show={MediaShow}
        icon={MediaIcon}
        options={{ label: messages.resources.media.name }}
    />,
    <Resource name="categories"
        list={checkPermissions(permissions, 'list-categories') ? CategoriesList : null}
        edit={checkPermissions(permissions, 'edit-categories') ? CategoriesEdit : NonPermission}
        create={checkPermissions(permissions, 'create-categories') ? CategoriesCreate : NonPermission}
        show={CategoriesShow}
        icon={CategoriesIcon}
        options={{ label: messages.resources.categories.name }}
    />,
    <Resource name="tags"
        list={checkPermissions(permissions, 'list-tags') ? TagsList : null}
        edit={checkPermissions(permissions, 'edit-tags') ? TagsEdit : NonPermission}
        create={checkPermissions(permissions, 'create-tags') ? TagsCreate : NonPermission}
        show={TagsShow}
        icon={TagsIcon}
        options={{ label: messages.resources.tags.name }}
    />,
    <Resource name="journalists"
        list={checkPermissions(permissions, 'list-journalists') ? JournalistList : null}
        edit={checkPermissions(permissions, 'edit-journalists') ? JournalistEdit : NonPermission}
        create={checkPermissions(permissions, 'create-journalists') ? JournalistCreate : NonPermission}
        icon={UserIcon}
        options={{ label: messages.resources.journalists.name }}
    />,
    <Resource name="subscribers"
        list={checkPermissions(permissions, 'list-subscribers') ? SubscriberList : null}
        edit={checkPermissions(permissions, 'edit-subscribers') ? SubscriberEdit : NonPermission}
        create={checkPermissions(permissions, 'create-subscribers') ? SubscriberCreate : NonPermission}
        icon={UserIcon}
        options={{ label: messages.resources.subscribers.name }}
    />,
    <Resource name="plans" />,
    <Resource name="roles"
        list={checkPermissions(permissions, 'list-roles') ? RoleList : null}
        edit={checkPermissions(permissions, 'edit-roles') ? RoleEdit : NonPermission}
        create={checkPermissions(permissions, 'create-roles') ? RoleCreate : NonPermission}
        icon={RoleIcon}
        options={{ label: messages.resources.roles.name }}
    />,
    <Resource name="authors"
        list={checkPermissions(permissions, 'list-authors') ? AuthorList : null}
        edit={checkPermissions(permissions, 'edit-authors') ? AuthorEdit : NonPermission}
        create={checkPermissions(permissions, 'create-authors') ? AuthorCreate : NonPermission}
        icon={AuthorIcon}
        options={{ label: messages.resources.authors.name }}
    />,
    /* 
    <Resource name="polls"
        list={checkPermissions(permissions, 'list-polls') ? PollsList : null}
        edit={checkPermissions(permissions, 'edit-polls') ? PollsEdit : NonPermission}
        create={checkPermissions(permissions, 'create-polls') ? PollsCreate : NonPermission}
        icon={PollIcon}
        options={{ label: messages.resources.polls.name }}
    />,
    */
    <Resource name="photographers"
        list={checkPermissions(permissions, 'list-photographers') ? PhotographersList : null}
        edit={checkPermissions(permissions, 'edit-photographers') ? PhotographersEdit : NonPermission}
        create={checkPermissions(permissions, 'create-photographers') ? PhotographersCreate : NonPermission}
        icon={PhotographersIcon}
        options={{ label: messages.resources.photographers.name }}
    />,
    /* 
    <Resource name="newspapers"
        list={checkPermissions(permissions, 'list-newspapers') ? NewspapersList : null}
        edit={checkPermissions(permissions, 'edit-newspapers') ? NewspapersEdit : NonPermission}
        create={checkPermissions(permissions, 'create-newspapers') ? NewspapersCreate : NonPermission}
        show={NewspapersShow}
        icon={NewspapersIcon}
        options={{ label: messages.resources.newspapers.name }}
    />, 
    */
    <Resource name="comments"
        list={checkPermissions(permissions, 'list-comments') ? CommentsList : null}
        icon={CommentIcon}
        options={{ label: messages.resources.comments.name }}
    />,

    <Resource
        name="submenu_metrics"
        options={{
            label: messages.resources.metrics.name,
            labelUsers: messages.resources.metrics.titles.title_users,
            labelHistory: messages.resources.metrics.titles.title_history,
        }}
    />,
    <Resource
        name="metrics-users"
        list={
            checkPermissions(permissions, "list-metrics") ? ViewUsers : NonPermission
        }
    />,
    <Resource
        name="visited-url"
        list={
            checkPermissions(permissions, "list-metrics")
                ? ViewVisitedUrl
                : NonPermission
        }
    />,

    <Resource name="settings"
        list={checkPermissions(permissions, 'list-config') ? SettingsList : null}
        icon={SettingsIcon}
        options={{ label: messages.resources.settings.name }}
    />,

    <Resource name="subscriptions" />,
    <Resource name="benefits"
        list={checkPermissions(permissions, 'list-benefits') ? BenefitsList : null}
    />,

    <Resource name='club_cards'
        list={checkPermissions(permissions, 'list-club-cards') ? ClubCardList : null}
        create={checkPermissions(permissions, 'create-club-cards') ? ClubCardCreate : NonPermission}
        edit={checkPermissions(permissions, 'edit-club-cards') ? ClubCardEdit : NonPermission}
        show={checkPermissions(permissions, 'list-club-cards') ? ClubCardShow : NonPermission}
    />,
    <Resource name="users" />,
    <Resource name="contact_form" create={ContactFormCreate} />,
    <Resource name="newspapers_categories" />
]

export default Resources;